import React from "react";
import "../components/layout.css";
import { Link, StaticQuery, graphql } from "gatsby";

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
            query image {
              file(relativePath: {eq: "404.jpg"}) {
                childImageSharp {
                  fixed(width: 1920, height: 1080) {
                    src
                  }
                }
              }
            }
          `}
    render={data => {
      return (
        <div className="body" style={{
          marginLeft: "5%",
          marginTop: 50,
          backgroundImage: `url(${data.file.childImageSharp.fixed.src})`,
          backgroundPositionY: "22%",
          backgroundPositionX: "25%",
        }}>
          <h1>PAGE NOT FOUND</h1>
          <p>May there be mercy on your soul!</p>
          <Link to='/' className="back">Return</Link>
        </div>
      );
    }}
  />
);

export default NotFoundPage;